import React from 'react';
import { RobotMapNameStyled } from './RobotMapName.styles';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';

interface RobotMapNameProps {
  mapName: string;
  className?: string;
  robot: Machine;
}

export const RobotMapName = ({ mapName, className, robot }: RobotMapNameProps): JSX.Element => {
  const states = robot?.states || [];
  const mapState = states.find(state => state.stateName === 'map');
  const showIcon = !!mapState?.stateValue;

  return (
    <RobotMapNameStyled gap={10} className={className}>
      {showIcon && <SvgIcon name="whiteDeviceMap" className="device-map-icon" />}
      <Tooltip title={mapName} placement="top">
        <span>{mapName}</span>
      </Tooltip>
    </RobotMapNameStyled>
  );
};
