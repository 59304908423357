import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest, select } from 'typed-redux-saga';
import isEqual from 'lodash-es/isEqual';
import {
  IGetFleetOperatingTimeErrorAction,
  IGetFleetOperatingTimeRequestAction,
  IGetFleetOperatingTimeSuccessAction,
  FleetOperatingTimeActions,
} from './fleetOperatingTimeActions';
import { FleetOperatingTimeSelectors } from './fleetOperatingTimeSelectors';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { FleetOperatingTimeResponse } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { LoadingBarActions } from 'app/cross-cutting-concerns/loading-bar/state/loadingBarSlice';

export function* getFleetOperatingTimeSaga(
  action: IGetFleetOperatingTimeRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<FleetOperatingTimeResponse>
  | PutEffect<IGetFleetOperatingTimeSuccessAction>
  | PutEffect<IGetFleetOperatingTimeErrorAction>
  | PutEffect<
      ReturnType<typeof LoadingBarActions.showLoadingBar> | ReturnType<typeof LoadingBarActions.hideLoadingBar>
    >,
  void,
  IDependencies
> {
  const cachedData = yield* select(FleetOperatingTimeSelectors.selectData);
  if (cachedData) {
    yield* put(LoadingBarActions.showLoadingBar());
  }

  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.getFleetOperatingTime, action.payload);
    if (!isEqual(response.fleetOperatingTime.data, cachedData)) {
      yield* put(FleetOperatingTimeActions.getFleetOperatingTimeSuccess(response));
    }
  } catch (error) {
    yield* put(
      FleetOperatingTimeActions.getFleetOperatingTimeError({
        error,
      })
    );
  } finally {
    if (cachedData) {
      yield* put(LoadingBarActions.hideLoadingBar());
    }
  }
}

export function* fleetOperatingTimeSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(FleetOperatingTimeActions.GET_FLEET_OPERATING_TIME_REQUEST, getFleetOperatingTimeSaga);
}
