import { i18n as i18next } from 'i18next';
import { TFunction } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { ReactNode } from 'react';
import { Flex } from 'antd';
import { DATE_FORMAT_PATTERN } from 'lib/utils/date-handling/DateTime.types';
import { CleaningTaskReport, TaskCompletion } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { Optional } from 'lib/types/Optional';
import { BarProgress } from 'lib/components/Progress/BarProgress/BarProgress';
import { StatusPill } from 'lib/components/StatusPill/StatusPill';
import { LoadingIndicator } from 'lib/components/LoadingIndicator/LoadingIndicator';
import { Numbers } from 'lib/utils/number-formatting/Numbers';
import { ConvertUnitUtils } from 'app/utils/convert-unit/ConvertUnitUtils';
import { ThemeConstants } from 'config/theme';

export const getRoutineCleaningReportListRobotColumns = ({
  t,
  i18n,
  handleClickDownload,
  currentExportingId,
  handleOpenRouteImage,
  isUSusers,
}: {
  t: TFunction;
  i18n: i18next;
  handleClickDownload: (ctr: CleaningTaskReport) => void;
  currentExportingId: Optional<string>;
  handleOpenRouteImage: (ctr: CleaningTaskReport) => void;
  isUSusers: boolean;
}): ColumnsType<CleaningTaskReport> => {
  const columns: ColumnsType<CleaningTaskReport> = [
    {
      title: t('cleaningReportList.robot.table.columns.date'),
      dataIndex: 'startedAt',
      width: 185,
      key: 'startedAt',
      className: 'route-list__column-title',
      render: (startedAt): JSX.Element => (
        <>
          {DateTime.formatDateByLocale(i18n.language, startedAt, DATE_FORMAT_PATTERN.DATE)}
          {', '}
          {DateTime.formatDateByLocale(i18n.language, startedAt, DATE_FORMAT_PATTERN.WEEKDAY)}
        </>
      ),
      fixed: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: 'imageRoute',
      width: 54,
      key: 'imageRoute',
      className: 'route-list__image-icon-column',
      render: (_, cleaningTaskReport): JSX.Element => (
        <div className="route-list__image-route-icon" onClick={(): void => handleOpenRouteImage(cleaningTaskReport)}>
          <SvgIcon name="image" className="image-icon" />
        </div>
      ),
      fixed: 'left',
      ellipsis: {
        showTitle: true,
      },
      align: 'center',
    },
    {
      width: 54,
      key: 'export',
      className: 'route-list__export-icon-column',
      render: (_id: string, cleaningTaskReport): JSX.Element => {
        if (!cleaningTaskReport.id) {
          return <></>;
        }
        return (
          <div className="table__link--no-styling" onClick={(): void => handleClickDownload(cleaningTaskReport)}>
            {currentExportingId === cleaningTaskReport.id ? (
              <LoadingIndicator size="small" />
            ) : (
              <SvgIcon name="exportReport" className="route-list__table_export-icon" />
            )}
          </div>
        );
      },
      fixed: 'left',
      ellipsis: {
        showTitle: true,
      },
      align: 'center',
    },
    {
      title: t('cleaningReportList.robot.table.columns.routeName'),
      dataIndex: 'routeName',
      width: 180,
      key: 'routeName',
      className: 'route-list__column-title',
      render: routeName => `${routeName || t('common.NA')}`,
      sorter: true,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('cleaningReportList.robot.table.columns.pathCoverage'),
      dataIndex: 'pathCoverage',
      width: 120,
      key: 'pathCoverage',
      className: 'route-list__column-title',
      render: (pathCoverage): JSX.Element => (
        <BarProgress
          percent={Math.round((pathCoverage || 0) * 100)}
          format={(percent?: number | undefined): ReactNode => {
            if (percent === 100) return `${percent}%`;
            return `${percent}%`;
          }}
        />
      ),
      align: 'left',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('cleaningReportList.robot.table.columns.taskCompletion'),
      className: 'route-list__task-column route-list__column-title',
      dataIndex: 'taskCompletion',
      width: 122,
      key: 'taskCompletion',
      render: (taskCompletion): JSX.Element => {
        let color = 'gray';
        switch (taskCompletion) {
          case TaskCompletion.Completed:
            color = 'green';
            break;
          case TaskCompletion.Interrupted:
            color = 'yellow';
            break;
          case TaskCompletion.Stopped:
            color = 'red';
            break;
          default:
            color = 'gray';
            break;
        }
        return (
          <StatusPill className="route-list__status-pill" color={color}>
            {`${
              taskCompletion
                ? t(`machineDetails.taskCompletionHistory.labels.${taskCompletion.toLowerCase()}`)
                : t('common.NA')
            }`}
          </StatusPill>
        );
      },
      sorter: true,
    },
    {
      title: t('cleaningReportList.robot.table.columns.cleanedSquareMeter'),
      dataIndex: 'cleanedSquareMeter',
      width: 130,
      key: 'cleanedSquareMeter',
      className: 'route-list__column-title',
      render: (cleanedSquareMeter): string =>
        isUSusers
          ? t('cleaningReportList.robot.table.values.cleanedSquareFeetValue', {
              cleanedSquareMeter: ConvertUnitUtils.covertSquareMetersToSquareFeet(cleanedSquareMeter || 0),
            })
          : t('cleaningReportList.robot.table.values.cleanedSquareMeterValue', {
              cleanedSquareMeter: Numbers.formatNumberDecimalRounded(cleanedSquareMeter || 0, 1),
            }),
      align: 'right',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: (): ReactNode => <Flex>{t('cleaningReportList.robot.table.columns.startTime')}</Flex>,
      dataIndex: 'startedAt',
      width: 87,
      key: 'startedAt',
      className: 'route-list__column-title',
      render: (startedAt): JSX.Element => (
        <>{DateTime.formatDateByLocale(i18n.language, startedAt, DATE_FORMAT_PATTERN.TIME_24_HOUR_WITH_SECOND)}</>
      ),
      ellipsis: {
        showTitle: true,
      },
      align: 'right',
    },
    {
      title: (): ReactNode => <Flex>{t('cleaningReportList.robot.table.columns.stopTime')}</Flex>,
      dataIndex: 'finishedAt',
      width: 87,
      key: 'finishedAt',
      className: 'route-list__column-title',
      render: (finishedAt): JSX.Element => (
        <>{DateTime.formatDateByLocale(i18n.language, finishedAt, DATE_FORMAT_PATTERN.TIME_24_HOUR_WITH_SECOND)}</>
      ),
      ellipsis: {
        showTitle: true,
      },
      align: 'right',
    },
    {
      title: (): ReactNode => <Flex>{t('cleaningReportList.robot.table.columns.duration')}</Flex>,
      dataIndex: 'actualOperatingTimeInSeconds',
      width: 101,
      key: 'actualOperatingTimeInSeconds',
      className: 'route-list__column-title',
      render: actualOperatingTimeInSeconds =>
        DateTime.formatDurationByMilliseconds({
          ms: actualOperatingTimeInSeconds * 1000,
        }),
      ellipsis: {
        showTitle: true,
      },
      align: 'right',
    },
    {
      title: (): ReactNode => <Flex>{t('cleaningReportList.robot.table.columns.power')}</Flex>,
      dataIndex: 'battery',
      width: 123,
      key: 'battery',
      className: 'route-list__column-title',
      render: battery => (
        <Flex gap={ThemeConstants.SPACING.XXS} align="center" className="route-list__battery-wrapper">
          <SvgIcon name="battery" />
          {`${Numbers.formatNumberDecimalRounded(battery || 0, 1)} kWh`}
        </Flex>
      ),
      ellipsis: {
        showTitle: true,
      },
      align: 'right',
    },
    {
      title: (): ReactNode => <Flex>{t('cleaningReportList.robot.table.columns.waterRoute')}</Flex>,
      dataIndex: 'water',
      width: 123,
      key: 'water',
      className: 'route-list__column-title',
      render: water => `${Numbers.formatNumberDecimalRounded(water || 0, 1)} l`,
      ellipsis: {
        showTitle: true,
      },
      align: 'right',
    },
    {
      title: (): ReactNode => <Flex>{t('cleaningReportList.robot.table.columns.detergentsRoute')}</Flex>,
      dataIndex: 'detergents',
      width: 123,
      key: 'detergents',
      className: 'route-list__column-title',
      render: detergents => `${Numbers.formatNumberDecimalRounded(detergents || 0, 1)} l`,
      ellipsis: {
        showTitle: true,
      },
      align: 'right',
    },
  ];

  return columns;
};
