import { Flex } from 'antd';
import styled from 'styled-components';

export const RobotMapNameStyled = styled(Flex)`
  i {
    min-width: 14px;
  }
  width: 140px;
  height: 24px;
  margin-top: -5px;
  margin-bottom: 5px;
  border-radius: 24px;
  text-align: center;
  padding: 5px 8px;
  background-color: ${(props): string => props.theme.colors.semanticInfo};

  color: ${(props): string => props.theme.colors.white};
  font-family: ${(props): string => props.theme.fonts.heading3};
  font-size: ${(props): string => props.theme.fontSizes.p1};
  font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
  line-height: ${(props): string => props.theme.lineHeights.p1};
  span {
    ${(props): string => props.theme.mixins.truncateText}
  }
  justify-content: center;
`;
