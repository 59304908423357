import styled from 'styled-components';

export const StyledCleaningReportListRobot = styled.div`
  .routine-list__expandable-header {
    ${(props): string => props.theme.fontStyles.caption.bold};
    text-transform: uppercase;
  }

  .routine-list__routine-icon {
    padding: 0;
    height: 100%;
  }

  .routine-icon {
    height: 24px !important;
    width: 24px !important;
    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }

  .routine-list__image-route-icon {
    cursor: pointer;

    .device-map-icon {
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }

    .image-icon {
      svg {
        width: 24px !important;
        height: 24px !important;
        display: block;
      }
    }
  }
  .routine-list__preview-route-image-wrapper {
    .routine-list__preview-route-image {
      display: none;
    }

    .ant-image-mask {
      display: none;
    }
  }

  .ant-table-container {
    table {
      .ant-table-tbody {
        tr > td.routine-list__task-column {
          padding: 4px ${(props): string => props.theme.spacing.sm};
        }
        tr > td.routine-list__image-icon-column {
          height: 48px;
          justify-content: center;
          align-items: center;
        }
        tr > td.routine-list__export-icon-column {
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
`;
