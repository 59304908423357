import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Col, Skeleton } from 'antd';
import { RobotBattery } from '../../RobotBattery/RobotBattery';
import { RobotImageConnectionStatus } from '../../RobotImageConnectionStatus/RobotImageConnectionStatus';
import { RobotCleaningStatus } from '../../RobotCleaningStatus/RobotCleaningStatus';
import { RobotConnectionStatusIndicator } from '../../RobotConnectionStatusIndicator/RobotConnectionStatusIndicator';
import { RobotName } from '../../RobotName/RobotName';
import { RobotModalsActions } from '../../../../modals/state/RobotModalsSlice';
import {
  selectRobotDashboardRealTimeIsComponentFadedOut,
  selectRobotDashboardRealTimeRobotId,
} from '../../../../state/RobotDashboardSelectors';
import { RobotMapName } from '../../RobotMapName/RobotMapName';
import { StyledRobotSummaryRowCV50 } from './RobotSummaryRowCV50.styles';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { MachinePictureLoader } from 'app/modules/machine-inventory/components/MachinePictureLoader/MachinePictureLoader';
import { LegacyTextButton } from 'lib/components/LegacyButton/LegacyTextButton/LegacyTextButton';
import { RobotUtils } from 'app/utils/robot/RobotUtils';
import {
  MachineConnectionStatus,
  RobotStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

interface RobotSummaryRowProps {
  robot: Machine;
  isShowStatus: boolean;
  areRobotListPicturesLoading: boolean;
  areRobotListTelemetriesLoading: boolean;
  areRobotListLatestCtrLoading: boolean;
}

export const RobotSummaryRowCV50 = ({
  robot,
  isShowStatus,
  areRobotListPicturesLoading,
  areRobotListTelemetriesLoading,
  areRobotListLatestCtrLoading,
}: RobotSummaryRowProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mapName = useMemo(() => RobotUtils.getRobotMapName(robot, t), [robot, t]);

  const robotRealTimeUpdatedId = useSelector(selectRobotDashboardRealTimeRobotId);
  const isRobotCardFadedOut = useSelector(selectRobotDashboardRealTimeIsComponentFadedOut);

  const { name, serialNumber, robotStatus, connectionStatus } = robot;

  const handleOpenRobotAssignSiteModal = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    dispatch(RobotModalsActions.showRobotAssignSiteModal({ robotId: robot.id }));
  };

  const isRobotRowFadedOut = useMemo(
    () => robot.id === robotRealTimeUpdatedId && isRobotCardFadedOut,
    [robot.id, robotRealTimeUpdatedId, isRobotCardFadedOut]
  );

  const robotSummaryRowClassName = classnames('robot-summary-row', {
    'robot-summary-row__faded-out': isRobotRowFadedOut,
    'robot-summary-row__faded-in': !isRobotRowFadedOut,
  });

  return (
    <StyledRobotSummaryRowCV50 className={robotSummaryRowClassName} justify="space-around" align="middle">
      <Col span={1} className="robot-summary-row__robot-image--wrapper">
        {robot?.variant?.picture && !areRobotListPicturesLoading ? (
          <RobotImageConnectionStatus
            imageUrl={robot?.variant?.picture.thumbnail}
            connectionStatus={connectionStatus}
          />
        ) : (
          <MachinePictureLoader />
        )}
      </Col>

      <Col span={2} className={`robot-summary-row__robot-connection-status--wrapper ${connectionStatus.toLowerCase()}`}>
        <RobotConnectionStatusIndicator lastActivityAt={robot?.lastActivityAt} connectionStatus={connectionStatus} />
      </Col>

      <Col span={3} className="robot-summary-row__robot-info--wrapper">
        <RobotName machineName={name} serialNumber={serialNumber} />
      </Col>

      <Skeleton
        active
        title={false}
        paragraph={{ rows: 2 }}
        loading={areRobotListTelemetriesLoading || areRobotListLatestCtrLoading}
      >
        {isShowStatus && (
          <Col span={3} className="robot-summary-row__robot-cleaning-status--wrapper">
            <RobotCleaningStatus robotStatus={robotStatus} connectionStatus={connectionStatus} />
          </Col>
        )}

        {!robot?.site?.name ? (
          <Col span={3} className="robot-summary-row__robot-cleaning-status--wrapper">
            <LegacyTextButton className="robot-summary-row__assign-site-btn" onClick={handleOpenRobotAssignSiteModal}>
              {t('robotDashboard.modals.assignSite.button.assignToSite')}
            </LegacyTextButton>
          </Col>
        ) : (
          <Col span={3} className="robot-summary-row__robot-cleaning-status--wrapper">
            {!isShowStatus && (
              <div className="robot-summary-row__robot-site-name-container">
                <span title={robot?.site?.name} className="robot-summary-row__robot-site-name">
                  {robot?.site?.name || t('common.NA')}
                </span>
              </div>
            )}
          </Col>
        )}
        {robot.robotStatus === RobotStatus.Autonomous && robot.connectionStatus === MachineConnectionStatus.Online && (
          <Col className="robot-summary-row__cleaning-map-name-wrapper">
            <RobotMapName mapName={mapName} className="robot-summary-row__cleaning-map-name" robot={robot} />
          </Col>
        )}

        <Col span={3} className="robot-summary-row__robot-battery--wrapper">
          <RobotBattery robot={robot} batteryPackNumber={1} />
        </Col>

        <Col span={3} className="robot-summary-row__robot-battery--wrapper">
          <RobotBattery robot={robot} batteryPackNumber={2} />
        </Col>
      </Skeleton>
    </StyledRobotSummaryRowCV50>
  );
};
